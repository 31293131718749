const sizes = {
  0: 0,
  1: '2px', // 2 ^ 1
  2: '4px', // 2 ^ 2
  3: '8px', // 2 ^ 3
  4: '16px', // 2 ^ 4
  5: '24px', // WARN, index 5 is custom, preferred by our designers
  6: '32px', // WARN, index 6 === 2 ^ 5
  7: '48px',
  8: '64px', // WARN, index 8 === 2 ^ 6
  9: '128px',
  10: '256px',
  11: '512px',

  icon: '24px',
  bigIcon: '172px',
  bigMobileIcon: '92px',
  giantIcon: '295px',
  planet: '460px',

  fullSize: '100%',
  browserWidth: '100vw',
  browserHeight: '100vh',

  pacman: '244px',
  halfPacman: '122px',
  pacmanFoodSize: '60px',
  pacmanFoodSpacing: '90px',
  // mobile:
  pacmanSmall: '110px',
  halfPacmanSmall: '55px',
  pacmanFoodSizeSmall: '25px',
  pacmanFoodSpacingSmall: '40px',

  // blog
  maxBlogContentWidthMobile: '725px',
  maxBlogContentWidthDesktop: '1050px',

  // rapid
  maxRapidContentWidthMobile: '725px',
  maxRapidContentWidthDesktop: '1120px',

  extraSmallMobileMaxWidth: '375px',

  mobileMaxWidthInPx: 640,
  mobileMaxWidth: '40em', // 640
  tabletMaxWidth: '56em', // 896
  desktopMaxWidth: '79em', // 1264
  widescreenMinWidth: '91em', // 1456
  extraWidescreenMinWidth: '125em', // 2000

  headerHeight: '80px',
  fsHeaderHeightMobile: '120px',
  fsHeaderHeightDesktop: '80px',

  banner: '79px',
  footerHeight: '36px',

  chart: '600px',
};

const zIndices = {
  image: 999,
  header: 1002,
  bubble: 1003,
  logo: 1004,
  mobileNav: 1005,
  popup: 9999,
};

const _fontSizes = {
  xxxs: '0.625rem', // used for Small text and inline edit only
  xxs: '1rem',
  xs: '1.125rem',
  s: '1.25rem', // === 20px
  m: '1.625rem', // mobile heading
  l: '2rem',
  xl: '3.375rem', // desktop heading
  xxl: '3.375rem', // kept for future purpose,
};
const fontSizes = {
  ...Object.entries(_fontSizes).reduce((mergedSizes, [key, value]) => ({ ...mergedSizes, [`${key}Important`]: `${value} !important` }), _fontSizes),
};

const fonts = {
  body: '"Open Sans", OpenSans, sans-serif',
  heading: '"Open Sans", OpenSans, sans-serif',
  bold: '"Open Sans", OpenSans, sans-serif',
  monospace: '"Menlo", monospace',
};

const _fontWeightNormal = 400;

const fontWeights= {
  body: _fontWeightNormal,
  normal: _fontWeightNormal, // duplicate and alias of body
  heading: 700,
  bold: 700,
};

const lineHeights= {
  body: 'normal',
  heading: 1.15,
};

const primaryRgbaObject = {
  r: 0, g: 189, b: 89, a: 1,
};
const primaryRgba = (opacity = 1) => {
  const { r, g, b } = primaryRgbaObject;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
const colors= {
  text: '#000',
  background: '#fff',
  primary: '#00bd59',
  secondary: '#009EE2',
  secondary2: '#7c889f',
  border: '#b2b2b2',
  muted: '#f2f4f6',
  mutedDark: 'rgba(92, 110, 142, 0.5)',
  mutedUi: '#bec5d2',
  warning: '#ff0000', // red
  black50Percent: 'rgba(0, 0, 0, 0.5)',
  white50Percent: 'rgba(256, 256, 256, 0.5)',
  barYellow: '#ffe200',
  strapiBlue: '#007eff',
  grey: {
    0: 'rgb(51, 51, 51)',
    1: 'rgb(126, 126, 126)',
    2: 'rgb(187, 187, 187)',
    3: 'rgb(225, 225, 225)',
    4: 'rgb(246, 246, 246)',
    5: 'rgb(250, 250, 250)',
    6: 'rgb(255, 255, 255)',
  },
  primaryDarker: {
    0: 'rgba(0, 140, 31, 0)',
    1: 'rgba(0, 140, 31, 0.1)',
    2: 'rgba(0, 140, 31, 0.3)',
    3: 'rgba(0, 140, 31, 0.5)',
    4: 'rgba(0, 140, 31, 0.7)',
    5: 'rgba(0, 140, 31, 0.9)',
    6: 'rgba(0, 140, 31, 1)',
  },
  primaryLighter: {
    0: primaryRgba(1),
    1: primaryRgba(0.9),
    2: primaryRgba(0.7),
    3: primaryRgba(0.5),
    4: primaryRgba(0.3),
    5: primaryRgba(0.1),
    6: primaryRgba(0),
  },
};

const _buttonStyles = {
  // flex: '1 1 auto',
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  // borderStyle: 'solid',
  // borderWidth: '1px',
  // borderColor: 'primaryLighter.1',
  outline: 'none',
  cursor: 'pointer',
  fontFamily: 'bold',
  fontWeight: 'bold',
  background: 'transparent',
  color: 'primary',
  fontSize: 'xxs',
  letterSpacing: '-0.2px',
  textDecoration: 'none',
  transition: 'all 0.2s ease-out',
  transformOrigin: 'center',

  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, 0.20) 0px 0px 5px', // NOTE: this should be valid only for buttons, for a tags it should be weird, so overrided for that definition
    transform: 'scale(1.05) !important',
  },
  '&:disabled': {
    // bg: 'rgba(0,211,99,0.4) !important',
    opacity: 0.4,
    cursor: 'default',
  },
  '&:disabled:hover': {
    textDecoration: 'none',
  },
};

const styles = {
  // should be used by Text by default, but doesn't work yet
  // https://github.com/system-ui/theme-ui/issues/1024
  default: {
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
    fontSize: 'xxs',
    margin: '0 0 8px 0',
    color: 'text',
  },

  // variants built extending text.default with additional props
  small: {
    variant: 'text.default',
    fontSize: 'xxxs',
  },
  strong: {
    variant: 'text.default',
    fontWeight: 'bold',
    fontSize: 'inherit',
    // color: 'inherit', // as we are originating from text.default, the color is injected from that variant. which is colors.text. Only if we would need the color to be used from the embedding HTML tag, we should enable color: inherit.
  },
  caps: {
    variant: 'text.default',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    // color: 'inherit', // as we are originating from text.default, the color is injected from that variant. which is colors.text. Only if we would need the color to be used from the embedding HTML tag, we should enable color: inherit.
  },
  antialiased: {
    variant: 'text.default',
    letterSpacing: 'normal',
    textShadow: '#fff 0px 1px 1px',
    webkitTextStroke: '0.45px rgba(0, 0, 0, 0.1)',
    webkitFontSmoothing: 'subpixel-antialiased',
    // color: 'inherit', // as we are originating from text.default, the color is injected from that variant. which is colors.text. Only if we would need the color to be used from the embedding HTML tag, we should enable color: inherit.
  },
  highlighted: {
    // backgroundImage: `linear-gradient(180deg, ${colors.primaryDarker[0]} 65%, ${colors.primaryDarker[2]} 65%, ${colors.primaryDarker[2]} 100%, ${colors.primaryDarker[0]} 100%)`,
    // backgroundPosition: 'center 2px',
    // backgroundRepeat: 'no-repeat',
  },
  highlightedLight: {
    // backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, rgba(255, 255, 255, 0.5) 65%, rgba(255, 255, 255, 0.5) 100%, rgba(255, 255, 255, 0) 100%)',
    // backgroundPosition: 'center 2px',
    // backgroundRepeat: 'no-repeat',
  },
  desktopHeaderBackground: {
    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 1) ${sizes.headerHeight}, rgba(255, 255, 255, 0) ${sizes.headerHeight})`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
  },
  desktopHeaderBackgroundInverted: {
    backgroundImage: `linear-gradient(180deg, ${primaryRgba(1)} ${sizes.headerHeight}, ${primaryRgba(0)} ${sizes.headerHeight})`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
  },
  grayScale: {
    variant: 'text.default',
    filter: 'grayscale(1);', // this is different than text.default with default color, as it impacts not only text, but everything including images inside
  },
  layoutMaxWidth: {
    maxWidth: 'desktopMaxWidth',
    m: 'auto',
  },
  wideLayoutMaxWidth: {
    maxWidth: 'widescreenMinWidth',
    m: 'auto',
  },
  blogTextMaxWidth: {
    maxWidth: 'maxBlogContentWidthMobile',
    width: '100%',
    mx: 'auto',
    px: [4, 4, 0],
    py: 0,
    img: {
      width: '100%',
    },
  },
  maxWidth: {
    width: '100%',
    maxWidth: 'unset',
    flex: '1 1 auto',
  },
  // used by default by Heading Component
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
    fontSize: ['m', 'xl', 'xl'],
    letterSpacing: '-0.5px',
    color: 'text',
  },

  toggle: {
    bg: 'rgba(0,0,0,0.3)',
    borderRadius: 5,
    p: 1,
    width: 'max-content',
    flex: '0 0 auto',

    '&>*, &>button': {
      width: 'max-content',
      flex: '0 0 auto',
      transform: 'scale(1) !important',
      py: 2,
      px: 4,
      fontWeight: 'normal',
      borderRadius: 5,
      fontSize: 'xxs',
      color: 'background',
      bg: 'transparent',
    },
    '&>button:hover': {
      transform: 'scale(1) !important',
    },
    '&>*:active, &>*.active': {
      color: 'text',
      bg: 'background',
    },
  },

  section: {
    variant: 'styles.layoutMaxWidth',
    minHeight: ['unset', 'browserHeight'],
    'main>&:first-of-type, main>&#contact, main>.pin-spacer>&': {
      minHeight: ['browserHeight', 'unset'],
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: process.env.NODE_ENV === 'development' ? 'dotted 1px lightgray' :'initial',
  },

  sampleChart: {
    filter: 'grayscale(100%)',
    opacity: 0.3,
  },

  /** ***********
   * The following items are due for further evaluation whether we are going to use them in text.* variant or Styled.* to generate the tags
  ************ */

  // default for Styled.*
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
    color: 'text',
  },
  // alias for text.heading variant
  h1: {
    variant: 'text.heading',
  },
  h2: {
    variant: 'text.heading',
    lineHeight: [1.35, 1.25],
    fontSize: ['s', 'm', 'l'],
  },
  h3: {
    variant: 'text.heading',
    color: 'primary',
    pt: 3,
    pb: 2,
    fontSize: ['xs', 's', 's'],
  },
  h4: {
    variant: 'text.heading',
    color: 'text',
    lineHeight: 1.35,
    fontWeight: ['bold', 'bold', 'normal'],
    fontSize: ['xs', 's', 'm'],
  },
  p: {
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
    fontSize: ['xs', 'xs', 'xxs'],
    pt: 5,
    pb: 4,
    span: {
      width: 'fullSize',
    },
  },
  fsText: {
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
    fontSize: 'xxsImportant',
    textAlign: 'left',
    margin: 0,
    padding: 0,
  },
  a: {
    ..._buttonStyles,
    '&[target=_blank] img': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'primary',
      borderRadius: 4,
    },
    '&[target=_blank]:hover img': {
      transition: 'all 0.1s ease',
      outline: `solid 2px ${colors.primary}`,
    },
    '&[target=_blank] :not(img):before': {
      content: '"↗ "',
      color: 'transparent',
      backgroundPosition: 'left 70%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 60%',
      backgroundImage: 'url(\'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAABOklEQVR4AbXUAUSDQRTA8VcRwSiEAABBCIIADAUI3rshTQqwAMDCEAoACEEoAKjobq3aDAEaghAAkAoJ13MO9iZ3vt7hb9zu2++74w3qrc1kYOkaLI7i5yl3CI6a4LAO92YZhqaW/I08CN84n+idGzF+M/Yyg4O5LAh8e5of+uF8tRoredDjzlJ1hHNmIw96bs9C16xxW1wLLJ2AwwuuH640ddou7edAeS9i8epvjDr/hsBfzoDD88T1nUWoJMI5tBFSRT64Y7H2EiFF5M6sg6NVsf4VIT0k7Ok3Fie+HzQXNJG4z0/x2rccWi1E/mW9yqHVRkK8vzcO4Z42Epp8hjpVkE+JyMDSkRxarZNIaBd4frjbgHRpW+8kicojsfJIrDAioaGpgaOnMoi8ugeaD5hANCGBYU8ZCf0Cfuhkz5VAZTYAAAAASUVORK5CYII=\')',
    },
    '&:hover': {
      boxShadow: 'none', // NOTE: this should be valid only for buttons, for a tags it should be weird.
      textDecoration: 'underline',
    },
  },
  pre: {
    fontFamily: 'monospace',
    overflowX: 'auto',
    code: {
      color: 'inherit',
    },
  },
  code: {
    fontFamily: 'monospace',
    fontSize: 'inherit',
    color: 'secondary',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  th: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  td: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
};

const _ctaButtonStyles = {
  ..._buttonStyles,
  borderRadius: 5,
  color: 'background',
  minWidth: '155px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  pl: 5,
  pr: 5,
  pb: 3,
  pt: 3,
  minHeight: '50px',
  m: 0,
  mr: 4,
  mb: 4,

  '&:active': {
    bg: 'primaryLighter.1',
  },

  '.more': {
    display: ['none', 'inherit'],
  },

  'svg, img': {
    width: 'auto',
    height: 'auto',
    flex: '1 0 auto',
  },
};

const buttons = {
  // default style used by Button, pure style
  primary: {
    ..._buttonStyles,
  },
  // no styling sohuld be applied really for this kind
  pure: {
    variant: 'text.default',
    background: 'none',
    outline: 'none',
    m: 0,
    p: 0,
    borderRadius: 0,
    minWidth: 'unset',
    minHeight: 'unset',
    textAlign: 'inherit',
    '&:hover': {
      boxShadow: 'none',
      transform: 'none !important',
    },
    cursor: 'pointer',
  },
  cta: {
    ..._ctaButtonStyles,
    fontWeight: 'bold',
    bg: 'primary',
  },
  ctaInvert: {
    ..._ctaButtonStyles,
    bg: 'background',
    color: 'primary',
    '&:active': {
      bg: 'background',
      color: 'primaryLighter.1',
    },
  },
  info: {
    ..._ctaButtonStyles,
    color: 'primary',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'primaryLighter.1',
  },
  contactIcon: {
    fontSize: '0px',
    minWidth: 'unset',
    width: '50px',
    px: 3,
    justifyContent: 'center',
    overflow: 'hidden',
    '.icon': {
      mr: 0,
    },
  },
  textAction: {
    color: 'pink',
    fontSize: 'xxxs',
    fontWeight: 'bold',
    border: 'solid 1px pink',
    borderRadius: 4,
    p: 2,
    ml: 1,
    height: 'max-content',
    minWidth: 'unset',
    bg: 'none',

    '&:hover': {
      textDecoration: 'none',
      color: 'background',
      bg: 'pink',
    },
  },
};

const _debugBorder = process.env.NODE_ENV === 'development' ? 'dotted 1px gray' :'initial';
const _debugResponsiveColor = process.env.NODE_ENV === 'development' ? {
  border: '3px dotted',
  borderColor: ['red', 'orange', 'blue', 'green'],
  bg: ['rgba(255, 0, 0, 0.1)', 'rgba(255, 165, 0, 0.1)', 'rgba(0, 0, 255, 0.1)', 'rgba(0, 255, 0, 0.1)'],
}: {};

const borders = {
  none: 'none',
  thin: '1px solid',
  thick: '3px solid',
  double: '3px double',
  debug: _debugBorder,
  responsive: _debugResponsiveColor,
};

const debug = {
  border: _debugBorder,
  responsive: _debugResponsiveColor,
};

export const config = {
  breakpoints: [
    // mobile
    sizes.mobileMaxWidth,
    // tablet
    sizes.tabletMaxWidth,
    // desktop
    sizes.desktopMaxWidth,
    // widescreen
    sizes.widescreenMinWidth,
    // super-widescreen
    sizes.extraWidescreenMinWidth,
  ],
};

const _colLeft = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'left',
  justifyContent: 'center',
  flex: '1 1 auto',
};

const flex = {
  grow: {
    flex: '1 0 auto',
  },
  shrink: {
    flex: '0 1 auto',
  },
  center: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: '1 1 auto',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '1 1 auto',
  },
  colCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flex: '1 1 auto',
  },
  colLeft: _colLeft,
  col: _colLeft,
  colRight: {
    ..._colLeft,
    alignItems: 'flex-end',
  },
  colAlternate: {
    variant: 'flex.colRight',
    width: 'fullSize',
    '>*': {
      maxWidth: '50%',
    },
    '>:nth-of-type(2n+1)': {
      alignSelf: 'flex-start',
    },
  },
  colAlternate2: {
    variant: 'flex.colLeft',
    width: 'fullSize',
    '>*': {
      maxWidth: '50%',
    },
    '>:nth-of-type(2n+1)': {
      alignSelf: 'flex-end',
    },
  },
  colAlternate3: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['flex-start', 'center', 'flex-start', 'flex-start'],
    '& > :nth-of-type(odd)': {
      alignSelf: ['flex-start', 'center', 'flex-end', 'flex-end'],
    },
    '& > *:not(:last-child)': {
      mb: [6, 0],
    },
  },
  row: {
    flexDirection: 'row',
  },
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const compactSectionContentPadding = [4, 7, 4, 0];

const _articleListOdds = Array.from({ length: 10 }, (v, index) => {
  const actualIndex = 2*index + 1; // all odds
  return ({
    [`&>:nth-of-type(${actualIndex})`]: {
      gridColumn: 2,
      gridRow: Math.ceil((actualIndex) / 2),
    },
  });
}).reduce((rules, rule) => ({ ...rules, ...rule }), {});

const grids = {
  default: {
    border: 'thin',
    borderColor: 'primary',
  },
  CompactSectionContentPadding: {
    padding: compactSectionContentPadding,
  },
  CompactSectionContent: {
    padding: compactSectionContentPadding, // have to have a non-DRY solution here, as referencing to variant grids.CompactSectionContentPadding was not working

    '& p': {
      pr: 7,
    },
    // flex
    display: 'flex',
    flexDirection: ['column-reverse', 'column-reverse', 'row'],
    justifyContent: 'flex-start',
    alignItems: 'center',
    // grid
    // display: 'grid',
    // gridGap: '0',
    // gridTemplateColumns: '1fr 1fr',
    '& &': {
      minHeight: 'auto',
    },
    'h1, h2': {
      maxWidth: ['unset', 'unset', '55vw', '620px'],
      width: 'fullSize',
    },
    'p, .paragraph': {
      maxWidth: ['unset', 'unset', '48vw', '620px'],
      width: 'fullSize',
    },
    '&>*': {
      variant: 'flex.colLeft',
      width: '100%',
      // flex
      flex: '1 1 55%',
      // maxWidth: '50%',
    },
    '&>aside:last-of-type': {
      flex: '1 1 45%',
      minHeight: ['25vh', '25vh', 'unset'],
      ml: [0, 0, 8],
      width: 'fullSize',
      [`@media screen and (max-width: ${sizes.tabletMaxWidth})`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  CompactSectionContentRTL: {
    variant: 'grids.CompactSectionContent',
    flexDirection: ['column-reverse', 'column-reverse', 'row-reverse'],
  },
  ContactScene: {
    display: 'flex',
    flexDirection: ['column-reverse', 'column-reverse'],
    alignItems: 'center',
    justifyContent: 'center',
    h2: {
      maxWidth: ['unset', '84vw !important', '60vw !important', '620px !important'],
      whiteSpace: ['normal', 'normal', 'normal'],
      pt: 4,
    },
    '&>*': {
      maxWidth: ['100%', '100%', '75%'],
      width: 'max-content',
      height: 'auto',
      flex: '0 0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    '&>aside:last-of-type': {
      ml: [0, 0],
      alignItems: 'center',
    },
    '.paragraph': {
      mb: 6,
    },
    'button, a>*': {
      variant: 'buttons.ctaInvert',
      minHeight: '60px',
      borderRadius: 8,
      px: 7,
    },
    'button, a, a>*': {
      mx: 'auto',
      width: 'max-content',
      minWidth: 'unset',
    },
  },
  CaseStudyContactScene: {
    variant: 'grids.ContactScene',
    minHeight: '0 !important',
    maxWidth: ['unset', `${sizes.maxBlogContentWidthMobile} !important`],
    flexDirection: 'column-reverse !important',
    h1: {
      fontSize: ['l', 'l', 'l'],
      maxWidth: ['unset !important', 'unset !important', 'unset !important', 'unset !important'],
    },
    '&>*': {
      pt: [4, 4, 4, 4],
      pb: [4, 4, 4, 7],
      maxWidth: ['unset !important', 'unset !important', 'unset !important'],
      flex: '1 0 auto',
    },
    '&>aside:last-of-type': {
      pt: [4, 4, 4, 7],
      pb: 0,
      alignItems: 'center',
      justifyContent: 'center',
      ml: 0,
      minHeight: '0 !important',
    },
  },
  StaticSectionContent: {
    variant: 'grids.CompactSectionContentPadding',
    width: 'fullSize',
    h1: {
      variant: 'styles.h1',
      color: 'primary',
    },
    h2: {
      variant: 'styles.h2',
      color: 'primary',
      mt: 5,
    },
    h3: {
      variant: 'styles.h3',
    },
    table: {
      border: 'thin',
      borderColor: 'grey.3',
      th: {
        bg: 'grey.3',
      },
      td: {
        verticalAlign: 'top',
      },
    },
  },
  articleList: {
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
    gap: [0, 0, 6],
    [`@media screen and (min-width: ${sizes.tabletMaxWidth})`]: {
      '&>:nth-of-type(odd)': {
        transform: 'translateY(-250px)',
      },
      ..._articleListOdds,
    },
  },
};

const lists = {
  basicListType: {
    li: {
      mt: 0,
      mb: 4,
      textDecoration: 'none',
    },
    'li:last-of-type': {
      mb: 5,
    },
  },
  primaryListType: {
    variant: 'lists.basicListType',
    listStyle: 'none',
    li: {
      // display: 'flex',
      paddingLeft: '24px',
    },

    'li::before': {
      flexShrink: '0',
      content: '"\\2022"',
      color: 'transparent',
      fontWeight: 'bold',
      display: 'flex',
      mt: '4px',
      width: '12px',
      height: '12px',
      backgroundColor: 'primary',
      borderRadius: '50%',
      marginRight: '12px',
      verticalAlign: '-20%',

      marginLeft: '-24px',
      marginTop: '6px',
      position: 'absolute',
    },
  },
  orderedListType: {
    variant: 'lists.basicListType',
    li: {
      listStylePosition: 'inside',
    },
  },
  airyListType: {
    li: {
      fontSize: 's',
      lineHeight: '200%',
      textDecoration: 'none',

      '&:before': {
        mt: '13px !important',
      },
    },
  },
  tagListType: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 3,

    li: {
      border: 'thin',
      borderColor: 'primary',
      borderRadius: 7,
      py: 3,
      px: 4,
      textDecoration: 'none',
      height: 'max-content',

      '&:before': {
        content: 'none',
        display: 'none',
      },
    },
  },
  whiteList: {
    variant: 'lists.basicListType',
    listStyle: 'none',
    color: 'background',
    mb: -8,
    li: {
      pl: '24px',
      pb: '5px',
      fontSize: ['xxs', 'xs'],
    },
    'li::before': {
      flexShrink: '0',
      content: '"\\2022"',
      color: 'background',
      fontWeight: '900',
      fontSize: '32px',
      display: 'flex',
      width: '0%',
      height: '0%',
      mt: '-10px',
      borderRadius: '50%',

      ml: '-24px',
      position: 'absolute',
    },
  },
};

// used for Card component
const cards = {
  primary: {
    p: [4, 4, 7],
    bg: 'background',
    marginBottom: 5,
    width: '100%',
    maxWidth: ['unset', 'unset', '50vw', '620px'],
    height: 'auto',
  },
};

const forms = {
  languageSelector: {
    fontSize: 'xxs',
    fontFamily: 'body',
    px: 3,
    py: '0 !important',
    border: 0,
    '&:focus': {
      border: 0,
      outline: 0,
    },
    '& + svg': {
      maxHeight: '22px',
    },
  },
  textInput: {
    height: 8,
    border: 0,
    boxShadow: 'none',
    padding: 4,
    fontFamily: 'body',
    fontSize: 's',
    ':focus': {
      outline: 0,
    },
    backgroundColor: 'background',
    borderRadius: 0,
  },
  radio: {
    path: {
      fill: 'primary',
    },
  },
  radioInverted: {
    path: {
      fill: 'background',
    },
  },
};

const _menuBorder = {
  borderBottomColor: 'primary',
};

const links = {
  nav: {
    fontSize: ['s', 'l', 'xxs'],
    textDecoration: 'none',
    letterSpacing: [0, '0.07px'],
    fontWeight: ['bold', 'normal'],
    color: 'text',
    py: [0, 0, 3],
    lineHeight: [1.2, 'normal'],
    borderBottomWidth: [0, 0, '3px'],
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderTopWidth: [0, 0, '3px'],
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',

    '&.active': {
      ..._menuBorder,
      color: ['background', 'background', 'text'],
    },
    '&:hover': {
      ..._menuBorder,
    },
  },
  external: (process.env.NODE_ENV === 'development###') ? {
    borderRadius: 4,
    borderTopRightRadius: 0,
    boxShadow: '6px 6px 3px 0px rgba(255,0,0,0.2)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundImage: 'url(\'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAABOklEQVR4AbXUAUSDQRTA8VcRwSiEAABBCIIADAUI3rshTQqwAMDCEAoACEEoAKjobq3aDAEaghAAkAoJ13MO9iZ3vt7hb9zu2++74w3qrc1kYOkaLI7i5yl3CI6a4LAO92YZhqaW/I08CN84n+idGzF+M/Yyg4O5LAh8e5of+uF8tRoredDjzlJ1hHNmIw96bs9C16xxW1wLLJ2AwwuuH640ddou7edAeS9i8epvjDr/hsBfzoDD88T1nUWoJMI5tBFSRT64Y7H2EiFF5M6sg6NVsf4VIT0k7Ok3Fie+HzQXNJG4z0/x2rccWi1E/mW9yqHVRkK8vzcO4Z42Epp8hjpVkE+JyMDSkRxarZNIaBd4frjbgHRpW+8kicojsfJIrDAioaGpgaOnMoi8ugeaD5hANCGBYU8ZCf0Cfuhkz5VAZTYAAAAASUVORK5CYII=\')',
  } : {},
  undercover: {
    textDecoration: 'none',
    color: 'inherit',
  },
  thin: {
    fontWeight: 'normal',
  },
};
const images = {
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'icon',
    width: 'icon',
    height: 'icon',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mr: 4,
    'svg, img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  pureCircleIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: ['bigMobileIcon', 9],
    height: ['bigMobileIcon', 9],
    p: [5, 6],
    boxShadow: 'none !important',
    backgroundColor: 'background',
    svg: {
      width: '100%',
      height: '100%',
    },
  },
  circleIcon: {
    variant: 'images.icon',
    overflow: 'hidden',
    flexBasis: ['bigMobileIcon', 9, 'bigIcon'],
    width: ['bigMobileIcon', 9, 'bigIcon'],
    height: ['bigMobileIcon', 9, 'bigIcon'],
    borderRadius: 'fullSize',
    p: [4, 6, 7],
    mr: [4, 4, 4],
    mb: [2, 4, 4],
    bg: 'background',
    boxShadow: [`${sizes[2]} ${sizes[2]} 0 0 ${colors.primaryDarker[6]}`, `${sizes[4]} ${sizes[4]} 0 -5px ${colors.primaryDarker[6]}`, `${sizes[4]} ${sizes[4]} 0 -5px ${colors.primaryDarker[6]}`],
  },
  fullWidth: {
    variant: 'images.icon',
    flex: '1 1 auto',
    width: 'fullSize',
    height: 'auto',
    'svg, img': {
      objectFit: 'cover',
    },
  },
  cover: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '100%',
    minHeight: '100%',
    height: 'auto',
    width: 'auto',
  },
  parallax: {
    width: 'auto',
    overflow: 'visible !important',

    '&.parallax-ok': {
      variant: 'flex.center',
      height: 'auto !important',
      width: '100%',
      transform: ['none', 'translateY(-30%)'],
    },
    '&.parallax-resize': {
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      // transformOrigin: 'bottom center', // this should come from the actual image, should not be generalized
      transform: 'scale(1.3)', // has to be inline with useAnimationParallax yPercent, which is currently -30 to 30%!
    },
  },
  fitHorizontalSvg: {
    svg: {
      display: 'block',
      width: 'auto',
      height: '100%',
    },
  },
  grayScale: {
    filter: 'grayscale(100%) brightness(1.2)',
    transition: 'all 0.2s ease-out',
  },
  grayScaleHover: {
    filter: 'grayscale(0%)',
  },
};

export default {
  ...config,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,

  // `styles` primarily for MDX, in React have to use Styled.h1, Styled.p etc.
  // NOTE: this seems like an alternative to variants and sx: define default styles for certain tags, and use those, instead of creating variants for Text, Heading etc.
  styles,
  // text variants are the same
  text: styles,

  colors,
  primaryRgbaObject,
  borders,
  debug,
  zIndices,
  buttons,
  flex,

  grids,
  lists,
  cards,
  forms,
  links,
  images,
  // let all spacing values also available for width/height as well as for margin/padding or border-radius
  sizes,
  space: sizes,
  radii: sizes,
  borderWidths: sizes,
  compactSectionContentPadding,
};
